<template>
  <window-content class="financeiro-window">
    <div class="w-m-header">
      <div>
        <h2>Remoção</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div v-if="!preventBind"  class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes da vistoria</legend>
          </e-col>
        </e-row>
        <div class="wrapper-sm">
          <e-row class="erp-row-col-md">

            <div class="col" style="max-width: 220px">
              <erp-s-field
                  view="lr"
                  label="ID:"
                  label-width="40px"
              >
                <erp-input autofocus simple-border v-model="remocao.id" readonly/>
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 300px">
              <erp-s-field
                  view="lr"
                  label="Data do Registro:"
              >
                <!--<datetime-input v-model="remocao.createdAt" disable simpleBorder/>-->
                <erp-input v-model="remocao.createdAt" disable simple-border />
              </erp-s-field>
            </div>

            <div class="col" style="max-width: 300px">
              <erp-s-field
                  view="lr"
                  label="Conclusão:"
              >
                <!--<datetime-input v-model="remocao.createdAt" disable simpleBorder/>-->
                <erp-input v-model="remocao.dataConclusao" disable simple-border />
              </erp-s-field>
            </div>

            <div class="col flex justify-end">
              <erp-s-field
                  view="lr"
                  label="Última modificação:"
              >
                <erp-input simple-border disable v-model="remocao.ultimaModificacao" v-mask="'##/##/#### ##:##:##'"
                           :after="[{icon: 'date_range', handler () {}}]"/>
              </erp-s-field>
            </div>
          </e-row>

          <e-row class="erp-row-col-md m-t-sm">

            <e-col>
              <erp-s-field
                  view="lr"
                  label="Bem:"
                  label-width="40px"
              >
                <bem-input @changeBem="changeBem" v-model="remocao.bem" ref=""/>
              </erp-s-field>
            </e-col>

            <!--          <div class="col">
                        <erp-s-field
                            view="lr"
                            label="Comitente:"
                        >
                          <comitente-input v-model="remocao.comitente" />
                        </erp-s-field>
                      </div>-->

            <div class="col" style="max-width: 180px">
              <status-select :disable="!remocao.id" label="Status:" :helper="statusHelper" placeholder="Selecione"
                             field-view="lr"
                             v-model="remocao.status"/>
            </div>

          </e-row>
          <e-row v-if="remocao && remocao.bem" class="erp-row-col-md m-b">

            <e-col style="max-width: 200px">
              <erp-s-field
                  view="lr"
                  label="Placa:"
                  label-width="40px"
              >
                <erp-input simple-border disable v-model="remocao.bem.placa" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="lr"
                  label="Chassi:"
              >
                <erp-input simple-border disable v-model="remocao.bem.chassi" />
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="lr"
                  label="Renavam:"
              >
                <erp-input simple-border disable v-model="remocao.bem.renavam" />
              </erp-s-field>
            </e-col>
          </e-row>
        </div>
      </div>
      <div class="body-content" :class="{bloqueadoBlur: !remocao.bem}">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Solicitações registradas</legend>
          </e-col>
        </e-row>
        <div class="m-t-sm">
          <solicitacoes-remocao :title="false" @edit="editarSolicitacao" :solicitacoes="remocao.solicitacoes" @reload="load(id || null)"/>
        </div>
      </div>
      <div class="body-content" v-if="!preventBindSolicitacao" :class="{bloqueadoBlur: !remocao.bem}">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Registro de Solicitação</legend>
          </e-col>
        </e-row>
        <!--<window-body :class="{bloqueadoBlur: !remocao.bem}">-->
        <div class="wrapper-md">
          <form v-on:submit.stop.prevent="registrarSolicitacao">
            <e-row class="m-b-md" style="padding-left: 112px">
              <strong>Recolher em:</strong>
            </e-row>
            <e-row mr>
              <e-col style="max-width: 240px">
                <uf-select required :error="solicitacaoError && !solicitacao.origem.uf" label-width="100px" helper="UF"
                           helper-position="lt" field-view="lr" label=""
                           v-model="solicitacao.origem.uf"
                           @localSelecionado="() => simpleReset(solicitacao.origem)"/>
              </e-col>
              <!--            <e-col v-if="!hideLocal" style="max-width: 200px">
                            <cidade-select :ignoreForceModel="isEditingSolicitacao" :disable="!solicitacao.origem.uf" :uf="solicitacao.origem.uf"
                                           :error="solicitacaoError && !solicitacao.origem.cidade"
                                           helper="Cidade" helper-position="lt" field-view="lr" no-label
                                           v-model="solicitacao.origem.cidade"/>
                          </e-col>-->
              <e-col v-if="!hideLocal2">
                <destino-select :ignoreForceModel="isEditingSolicitacao" :disable="!solicitacao.origem.cidade"
                                field-view="lr" helper="Localização"
                                helper-position="lt" no-label
                                :cidade="solicitacao.origem.cidade"
                                :uf="solicitacao.origem.uf"
                                v-model="solicitacao.origem.localizacao"
                                :local="solicitacao.origem"
                                :error="solicitacaoError && !solicitacao.origem.localizacao"
                                @showMap="mostraMapaLocalOrigem"
                                @localSelecionado="(v) => selecionaLocal(solicitacao.origem, v)"
                />
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Endereço:"
                    label-width="100px"
                    :error="solicitacaoError && !solicitacao.origem.endereco"
                >
                  <erp-input v-model="solicitacao.origem.endereco"/>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row class="m-b-md m-t-xs" style="padding-left: 112px">
              <strong>Entregar em:</strong>
            </e-row>

            <e-row mr class="m-t-sm">
              <e-col class="m-r-xs" style="max-width: 240px">
                <uf-select label-width="100px" field-view="lr" label="Destino:" v-model="solicitacao.destino.uf"
                           :error="solicitacaoError && !solicitacao.destino.uf" helper="UF" helper-position="lt"
                           @localSelecionado="() => simpleReset(solicitacao.destino)"/>
              </e-col>
              <!--            <e-col v-if="!hideLocal" class="m-r-xs" style="max-width: 200px">
                            <cidade-select :ignoreForceModel="isEditingSolicitacao" :disable="!solicitacao.destino.uf" :uf="solicitacao.destino.uf" field-view="lr" no-label
                                           v-model="solicitacao.destino.cidade"
                                           :error="solicitacaoError && !solicitacao.destino.cidade" helper="Cidade" helper-position="lt"/>
                          </e-col>-->
              <e-col v-if="!hideLocal2">
                <destino-select :ignoreForceModel="isEditingSolicitacao" :disable="!solicitacao.destino.cidade"
                                field-view="lr" no-label
                                helper="Localização" helper-position="lt"
                                :cidade="solicitacao.destino.cidade"
                                :uf="solicitacao.destino.uf"
                                v-model="solicitacao.destino.localizacao"
                                :local="solicitacao.destino"
                                :error="solicitacaoError && !solicitacao.destino.localizacao"
                                @showMap="mostraMapaLocalDestino"
                                @localSelecionado="(v) => selecionaLocal(solicitacao.destino, v)"
                />
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Endereço:"
                    label-width="100px"
                    :error="solicitacaoError && !solicitacao.destino.endereco"
                >
                  <erp-input v-model="solicitacao.destino.endereco"/>
                </erp-s-field>
              </e-col>
            </e-row>

            <local-remocao v-if="showLocalMap" ref="mapLocal"/>

            <div class="row justify-between m-t-sm">
              <div class="col" style="max-width: 400px; min-width: 300px">
                <erp-s-field
                    view="lr"
                    label="Reboquista:"
                    label-width="100px"
                    :error="solicitacaoError && !solicitacao.reboquista"
                >
                  <reboquista-input
                      v-if="!hideLocal && solicitacao.origem && solicitacao.origem.endereco && solicitacao.destino && solicitacao.destino.endereco"
                      :trajeto="[solicitacao.origem, solicitacao.destino]"
                      v-model="solicitacao.reboquista"
                      :solicitacao="solicitacao"
                      @reboquistaSelecionado="selecionaReboquista"
                  />
                  <erp-input disable v-else v-model="fakeReboquista" />
                  <!-- TODO: Criar filtro de reboquistas baseado no trajeto -->
                </erp-s-field>
              </div>
              <div class=" m-l flex-zero w-auto" style="max-width: 140px">
                <erp-s-field
                    view="lr"
                    label="KM previsto:"
                >
                  <span>{{ kmPrevisto }}</span>
                </erp-s-field>
              </div>
              <div class=" m-l flex-zero w-auto">
                <erp-s-field
                    view="lr"
                    label="Tempo previsto:"
                >
                  <span>{{ tempoPrevisto }}</span>
                </erp-s-field>
              </div>
              <div class=" m-l flex-zero w-auto self-end">
                <e-btn @click="calcularRota" label="Calcular rota" class="text-nowrap" :disable="!trajetoValido"
                       :title="!trajetoValido && 'Para calcular é necessário montar o trajeto'"/>
              </div>
            </div>

            <e-row class="m-t-sm">
              <e-col style="max-width: 300px">
                <erp-s-field
                    view="lr"
                    label="Custo:"
                    label-width="100px"
                >
                  <helper-input-btn>
                    <erp-input disable placeholder="R$ 0,00" slot="input" v-model="solicitacao.custo"/>
                    <a @click="lancarCustoManual" class="no-select"><small class="text-black small flex">
                      <u-icon name="external-link-alt" color="black" style="font-size: 12px" class="m-r-xs" type="fa"/>
                      Manual</small></a>

                  </helper-input-btn>
                </erp-s-field>
              </e-col>

              <e-col>
                <classificacao-select @selected="selecionarClassificacao" label-width="90px" field-view="lr"
                                      label="Classificação:" v-model="solicitacao.classificacao"/>
              </e-col>

              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="110px"
                    label="Remoção local:"
                    helper-position="lb"
                >
                  <erp-checkbox v-model="solicitacao.remocaoLocal"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Valor R/Local:"
                    label-width="100px"
                >
                  <erp-input v-money="money" :disable="!solicitacao.remocaoLocal" placeholder="R$ 0,00"
                             v-model="solicitacao.remocaoLocalValor"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <div class="row no-select">
              <div class="col" style="max-width: 300px">
                <erp-s-field
                    view="lr"
                    label=" "
                    label-width="100px"
                >
                  <span class="text-primary small">Custo total: {{ custoTotal }}</span>
                </erp-s-field>
              </div>
            </div>

            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Prazo aceite:"
                    label-width="100px"
                >
                  <datetime-input date-type="datetime" v-model="solicitacao.prazoAceite" simpleBorder/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Prazo recolhimento:"
                >
                  <datetime-input date-type="datetime" v-model="solicitacao.prazoRecolhimento" simpleBorder/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Prazo entrega:"
                >
                  <datetime-input date-type="datetime" v-model="solicitacao.prazoEntrega" simpleBorder/>
                </erp-s-field>
              </e-col>
            </e-row>

            <div class="row m-t">
              <div class="col m-r-md">
                <erp-s-field
                    view="tl"
                    label="Observações Reboquista:"
                    label-width="200px"
                    wrap
                >
                  <textarea v-model="solicitacao.observacaoReboquista" class="full-width sl-textarea wrapper-xs"
                            rows="4"></textarea>
                </erp-s-field>
              </div>
              <div class="col">
                <erp-s-field
                    view="tl"
                    label="Observações Internas:"
                    label-width="200px"
                    wrap
                >
                  <textarea v-model="solicitacao.observacaoInterna" class="full-width sl-textarea wrapper-xs" rows="4"></textarea>
                </erp-s-field>
              </div>
            </div>

            <div class="row m-t-lg">
              <div class="col m-r-md">
                  <e-btn type="submit" label="Registrar solicitação"/>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
      </div>
      <div class="text-right">
        <div class="inline-block window-actions">
          <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
                 @click="$uloc.window.close($root.wid)"/>
          <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
                 @click="save"
                 v-shortkey="['f2']" @shortkey.native="save"/>
        </div>
      </div>
    </div>
  </window-content>
</template>

<script>
import {
  WindowContent,
  WindowHeader,
  WindowBody,
  WindowFooter,
  ErpSField,
  ErpInput,
  HelperInputBtn,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import StatusSelect from '../include/StatusSelect'
import UfSelect from '../include/UfSelect'
import CidadeSelect from '../include/CidadeSelect'
import DestinoSelect from '../include/DestinoSelect'
import SolicitacoesRemocao from './RemocaoComponents/Solicitacoes'
import {date} from 'uloc-vue'
import WindowSuccess from '../../../layout/window/Success'
import WindowLoading from '../../../layout/window/Loading'
import TimerCloseWindow from '../../../layout/window/TimerClose'
import DatetimeInput from '../../../../reuse/input/Datetime'
import BemInput from '../../../bem/helpers/input/BemInput'
// import ComitenteInput from '../../../comitente/helpers/input/ComitenteInput'
import ReboquistaInput from '../../../reboquista/helpers/input/ReboquistaInput'
import {find, newRemocao, updateRemocao} from '@/domain/remocao/services'
import LocalRemocao from '../maps/Local'
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {datetimeToEn} from "@/utils/date"
import ClassificacaoSelect from "@/components/remocao/components/include/ClassificacaoSelect"
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'

let mockSolicitacao = {
  id: null,
  origem: {
    nome: null,
    uf: null,
    cidade: null,
    localizacao: null,
    endereco: null,
    lat: null,
    lng: null
  },
  destino: {
    nome: null,
    uf: null,
    cidade: null,
    localizacao: null,
    endereco: null,
    lat: null,
    lng: null
  },
  reboquista: null,
  custo: null,
  observacaoReboquista: null,
  observacaoInterna: null,
  data: {date: null},
  prazoAceite: null,
  prazoRecolhimento: null,
  prazoEntrega: null,
  extra: {},
  classificacao: null,
  remocaoLocal: false,
  remocaoLocalValor: null
}

export default {
  name: 'RemocaoWindow',
  provide: function () {
    return {
      remocaoContainer: this
    }
  },
  directives: {money: VMoney},
  props: ['id', 'bemProp'],
  data() {
    return {
      money: REAL_BRL,
      loading: !!this.id,
      preventBind: true,
      dataRemocao: null,
      remocao: {
        id: null,
        createdAt: null,
        ultimaModificacao: null,
        bem: null,
        comitente: null,
        status: null,
        solicitacoes: []
      },
      solicitacao: JSON.parse(JSON.stringify(mockSolicitacao)),
      solicitacaoError: false,
      map: null,
      showLocalMap: false,
      tempoPrevisto: '-',
      kmPrevisto: '-',
      cache: {
        custos: []
      },
      isEditingSolicitacao: false,
      hideLocal: false,
      hideLocal2: false,
      fakeReboquista: null,
      preventBindSolicitacao: false
    }
  },
  mounted() {
    this.map = this.$refs.mapLocal
    if (this.id) {
      this.load(this.id)
    } else {
      this.preventBind = false
    }
    if (this.bemProp) {
      setTimeout(() => {
        console.log('BEM', this.bemProp)
        this.remocao.bem = this.bemProp
        this.changeBem(this.bemProp)
      }, 400)
    }
  },
  watch: {},
  computed: {
    statusHelper() {
      let status = 'Será atualizado após salvar'
      if (this.remocao.id) {
        status = ''
      }
      return status
    },
    trajetoValido() {
      return this.solicitacao.origem.endereco && this.solicitacao.destino.endereco
    },
    custoTotal() {
      let subtotal = 0
      this.cache.custos.map(c => {
        subtotal = subtotal + Number(c.valor)
      })
      if (this.solicitacao.remocaoLocalValor) {
        subtotal = subtotal + Number(this.$options.filters.moedaToNumber(this.solicitacao.remocaoLocalValor))
      }
      let total = Number(this.solicitacao.custo) + subtotal
      return 'R$' + this.$options.filters.moeda(total)
    }
  },
  methods: {
    load(id) {
      if (typeof id === 'undefined' || !id) {
        id = this.id
      }
      find(id)
          .then(response => {
            this.loading = false
            if (response.data.data && response.data.data.date) {
              response.data.data = date.formatDate(response.data.data.date, 'DD/MM/YYYY HH:mm')
            }

            if (response.data.ultimaModificacao && response.data.ultimaModificacao.date) {
              response.data.ultimaModificacao = date.formatDate(response.data.ultimaModificacao.date, 'DD/MM/YYYY HH:mm:ss')
            }
            if (response.data.dataConclusao && response.data.dataConclusao.date) {
              response.data.dataConclusao = date.formatDate(response.data.dataConclusao.date, 'DD/MM/YYYY HH:mm:ss')
            }
            response.data.createdAt = date.formatDate(response.data.createdAt.date, 'DD/MM/YYYY HH:mm:ss')
            this.preventBind = true
            this.remocao = response.data
            if (!this.remocao.solicitacoes) {
              this.remocao.solicitacoes = []
            }
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
          })
          .catch((error) => {
            this.$uloc.dialog({
              title: 'Falha ao carregar!',
              color: 'negative',
              message: `Não conseguimos carregar a remoção, entre em contato com o administrador.`
            })
            this.loading = false
            this.errors = error.data
            console.error(error, error.data)
          })
    },
    registrarSolicitacao() {
      try {
        console.log(this.solicitacao)
        this.solicitacaoError = false
        if (!this.solicitacao.origem.uf /*|| !this.solicitacao.origem.cidade*/ || !this.solicitacao.destino.uf /*|| !this.solicitacao.destino.cidade*/ || !this.solicitacao.reboquista) {
          this.$uloc.notify({
            color: 'negative',
            message: 'Preencha todos os campos da solicitação antes de registrá-la.'
          })
          this.solicitacaoError = true
          return
        }
        if (this.solicitacao.data && !this.solicitacao.data.date) {
          this.solicitacao.data = {date: date.convertDateToFormat(new Date())}
        }
        this.remocao.solicitacoes.push(this.solicitacao)
        console.log(this.remocao.solicitacoes)
        this.clearSolicitacao()
        this.$uloc.notify({color: 'positive', message: 'Solicitação adicionada. Salve as alterações para processá-la.'})
      } catch (e) {
        console.log(e)
        this.$uloc.notify({color: 'negative', message: e.message})
      }
    },
    lancarCustoManual() {
      this.$uloc.dialog({
        title: 'Permissão negada!',
        message: `Você precisa de permissão de gerência para efetuar esta operação.`
      })
    },
    clearSolicitacao() {
      this.solicitacao = JSON.parse(JSON.stringify(mockSolicitacao))
    },
    save() {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.remocao))
      delete data.createdAt
      if (data.solicitacoes && data.solicitacoes.length) {
        const l2 = []
        for (let i = 0; i < data.solicitacoes.length; i++) {
          if (data.solicitacoes[i].id) {
            continue
          }
          delete data.solicitacoes[i].data // tmp
          // data.solicitacoes[i].data = data.solicitacoes[i].data.date
          if (data.solicitacoes[i].reboquista) {
            data.solicitacoes[i].reboquista = data.solicitacoes[i].reboquista.id
          }
          data.solicitacoes[i].origem.latitude = data.solicitacoes[i].origem.lat
          data.solicitacoes[i].origem.longitude = data.solicitacoes[i].origem.lng
          data.solicitacoes[i].origem.cidadeOld = data.solicitacoes[i].origem.cidade && data.solicitacoes[i].origem.cidade.nome ? data.solicitacoes[i].origem.cidade.nome : data.solicitacoes[i].origem.cidade
          data.solicitacoes[i].origem.ufOld = data.solicitacoes[i].origem.uf
          data.solicitacoes[i].origem.local = data.solicitacoes[i].origem.localizacao

          data.solicitacoes[i].destino.latitude = data.solicitacoes[i].destino.lat
          data.solicitacoes[i].destino.longitude = data.solicitacoes[i].destino.lng
          data.solicitacoes[i].destino.cidadeOld = data.solicitacoes[i].destino.cidade && data.solicitacoes[i].destino.cidade.nome ? data.solicitacoes[i].destino.cidade.nome : data.solicitacoes[i].destino.cidade
          data.solicitacoes[i].destino.ufOld = data.solicitacoes[i].destino.uf
          data.solicitacoes[i].destino.local = data.solicitacoes[i].destino.localizacao

          if (data.solicitacoes[i].prazoAceite) {
            data.solicitacoes[i].prazoAceite = datetimeToEn(data.solicitacoes[i].prazoAceite)
          }
          if (data.solicitacoes[i].prazoRecolhimento) {
            data.solicitacoes[i].prazoRecolhimento = datetimeToEn(data.solicitacoes[i].prazoRecolhimento)
          }
          if (data.solicitacoes[i].prazoEntrega) {
            data.solicitacoes[i].prazoEntrega = datetimeToEn(data.solicitacoes[i].prazoEntrega)
          }

          if (data.solicitacoes[i].remocaoLocalValor) {
            data.solicitacoes[i].remocaoLocalValor = this.$options.filters.moedaToNumber(data.solicitacoes[i].remocaoLocalValor)
            if (!data.solicitacoes[i].remocaoLocalValor) {
              data.solicitacoes[i].remocaoLocalValor = null
            }
          }

          data.solicitacoes[i].extra = JSON.stringify(data.solicitacoes[i].extra)
          if (data.id) {
            data.solicitacoes[i].remocao = data.id
          }
          l2.push(data.solicitacoes[i])
        }
        data.solicitacoes = l2
      }
      if (data.bem && data.bem.id) {
        data.bem = data.bem.id
      }
      console.log(data)
      let method = data.id ? updateRemocao(data.id, data) : newRemocao(data)
      method
          .then(({data}) => {
            this.remocao.id = data.id
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                // this.$refs.timer.start()
                this.load(data.id)
                this.dg()
                this.$uloc.window.emit(this.$root.wid, 'update', this.props)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            console.log(error)
            this.success = false
            this.errorMessage = error.data.message
            // this.errors = this.systemError(error)
            this.$nextTick(() => {
              this.loading = false
              this.alertApiError(error)
              // this.error = true
            })
          })
    },
    mostraMapaLocal(local, tipo) {
      console.log('Mostra', local, tipo)
      this.showLocalMap = true
      this.$nextTick(() => {
        this.$refs.mapLocal.show(
            local, tipo === 'origem' ? this.confirmarLocalizacaoOrigem : this.confirmarLocalizacaoDestino
        )
      })
    },
    calcularRota() {
      if (!this.trajetoValido) return
      this.tempoPrevisto = '-'
      this.kmPrevisto = '-'
      console.log('Calc rota')
      this.showLocalMap = true
      this.$refs.mapLocal.simpleShow()
      this.$nextTick(() => {
        this.$refs.mapLocal.getRounting(
            {query: this.solicitacao.origem.endereco},
            {query: this.solicitacao.destino.endereco}
        ).then(response => {
          // @TODO: Salvar na solicitação as informações da rota
          // this.solicitacao.extra = Object.assign(this.solicitacao.extra, {rota: response}) @TODO: Bug, armazena muito dado
          this.tempoPrevisto = response.routes[0].legs[0].duration.text
          this.kmPrevisto = response.routes[0].legs[0].distance.text
          // const custo = (response.routes[0].legs[0].distance.value / 1000) * 3.12
          // this.solicitacao.custo = 'R$' + this.$options.filters.moeda(custo)
          // this.cache.custo = custo
        })
      })
    },
    mostraMapaLocalOrigem(local) {
      this.mostraMapaLocal(local, 'origem')
    },
    mostraMapaLocalDestino(local) {
      this.mostraMapaLocal(local, 'destino')
    },
    confirmarLocalizacaoOrigem(location) {
      console.log('Confirm location Origem: ', location)
      this.solicitacao.origem.endereco = location.address
      this.solicitacao.origem.lat = location.latLng.lat
      this.solicitacao.origem.lng = location.latLng.lng
    },
    confirmarLocalizacaoDestino(location) {
      console.log('Confirm location Destino: ', location)
      this.solicitacao.destino.endereco = location.address
      this.solicitacao.destino.lat = location.latLng.lat
      this.solicitacao.destino.lng = location.latLng.lng
    },
    selecionaLocal(model, local) {
      console.log('Local selecionado: ', local)
      model.nome = local.nome
      model.endereco = local.endereco
      model.latitude = local.latitude
      model.longitude = local.longitude
      model.cidade = {id: local.cidadeOldId, nome: local.cidadeOld}
    },
    changeBem(v) {
      if (!v || Number(v) === 0) return
      console.log('Mudou o bem', v)
      if (v.comitente.prazoRemocao) {
        const prazo = v.comitente.prazoRemocaoHoras
        const now = new Date()
        const prazoAceite = date.addToDate(now, {minutes: 15})
        const prazoRecolhimento = date.addToDate(now, {hours: (Number(prazo) / 2)})
        const prazoEntrega = date.addToDate(now, {hours: prazo})
        this.solicitacao.prazoAceite = date.formatDate(prazoAceite, 'DD/MM/YYYY HH:mm')
        this.solicitacao.prazoRecolhimento = date.formatDate(prazoRecolhimento, 'DD/MM/YYYY HH:mm')
        this.solicitacao.prazoEntrega = date.formatDate(prazoEntrega, 'DD/MM/YYYY HH:mm')
      }
    },
    editarSolicitacao(s) {
      // @TODO: Converter data para PT
      console.log(s)
      this.preventBindSolicitacao = true
      this.isEditingSolicitacao = true
      this.hideLocal = true
      this.hideLocal2 = true
      this.solicitacao = s
      this.remocao.solicitacoes.splice(this.remocao.solicitacoes.indexOf(s), 1)
      this.$nextTick(() => {
        setTimeout(() => {
          this.preventBindSolicitacao = false
          setTimeout(() => {
            this.hideLocal = false
            setTimeout(() => {
              this.hideLocal2 = false
              setTimeout(() => {
                this.isEditingSolicitacao = false
              }, 1000)
            }, 100)
          }, 100)
        }, 1)
      })
    },
    simpleReset(model) {
      /* model.cidade = null
      model.endereco = null
      model.lat = null
      model.lng = null
      model.nome = null
      model.endereco = null
      model.latitude = null
      model.longitude = null
      model.localizacao = null */
    },
    selecionaReboquista(r) {
      console.log('Selecionou', r)
      this.solicitacao.custo = r.valor
    },
    selecionarClassificacao(c) {
      console.log('Selecionou', c)
      let find = this.cache.custos.find(c => c.id === 'classificacao')
      if (find) {
        this.cache.custos.splice(this.cache.custos.indexOf(find), 1)
      }
      c && this.cache.custos.push({id: 'classificacao', valor: c.custoAdicional})
    }
  },
  components: {
    ClassificacaoSelect,
    ECol,
    ERow,
    LocalRemocao,
    ReboquistaInput,
    // ComitenteInput,
    BemInput,
    DatetimeInput,
    //TimerCloseWindow,
    //WindowLoading,
    //WindowSuccess,
    SolicitacoesRemocao,
    DestinoSelect,
    // CidadeSelect,
    UfSelect,
    StatusSelect,
    //WindowFooter,
    //WindowBody,
    //WindowHeader,
    WindowContent,
    ErpSField,
    ErpInput,
    HelperInputBtn,
    ErpCheckbox
  }
}
</script>
